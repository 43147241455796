import type { OpusContactType, OrganizationSector, SupplierType } from "@/core/api/graphql/types";
import type { OpusApprovalOrderStatuses } from "@/shared/opus";

export enum RegistrationKind {
  personal = "personal",
  organization = "organization",
}

export type RegisterOrganization = {
  userName: string;
  email: string;
  password: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  organizationName?: string;
};

export type SignMeUp = {
  userName: string;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
};

export type ForgotPassword = {
  email: string;
  resetPasswordUrlPath: string;
};

export type ResetPassword = {
  userId: string;
  token: string;
  password: string;
};

export type ChangePassword = {
  userId: string;
  oldPassword: string;
  newPassword: string;
};

export type CheckoutDefaults = {
  deliveryMethod?: string;
  shippingMethodId?: string;
  paymentMethodCode?: string;
};

export type UserPersonalData = {
  firstName: string;
  lastName: string;
  defaultLanguage?: string;
  currencyCode?: string;
  phones?: string[];
};

export type OrdersFilterData = {
  statuses: string[];
  startDate?: string;
  endDate?: string;
  // OPUS
  suppliers: SupplierType[];
  approvalStatuses: OpusApprovalOrderStatuses[];
  buyers: OpusContactType[];
  // !OPUS
};

export type OrdersFilterChipsItem = {
  fieldName: keyof OrdersFilterData;
  value?: string;
  label: string;
};

// OPUS
export enum OrganizationStatus {
  new = "New",
  approved = "Approved",
  unverified = "Unverified",
}

export type OpSignMeUpType = {
  userName: string;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  organizationName?: string;
  phoneNumber?: string;
  jobTitle?: string;
  zipCode?: string;
  discoveryWay: string;
  createdWay?: string;
  subSource?: string;
  organizationType: OrganizationSector;
};

export type OpRegisterOrganizationType = {
  userName: string;
  email: string;
  password: string;
  firstName?: string;
  lastName?: string;
  middleName?: string;
  organizationName?: string;
  phoneNumber?: string;
  jobTitle?: string;
  zipCode?: string;
  discoveryWay: string;
  createdWay?: string;
  subSource?: string;
};

export type OpShortOrderType = {
  CustomerOrderNumber: string;
  SupplierOrderNumber?: string;
  SupplierName?: string;
  CreatedDate: string;
  CustomerName?: string;
  PaymentType?: string;
  SupplierOrderStatus?: string;
  ShippingTotal?: string;
  TaxTotal?: string;
  SupplierOrderEstimatedTotal?: string;
  UpdatedShippingTotal?: string;
  UpdatedTaxTotal?: string;
  SupplierOrderActualTotal?: string;
  ContractNumbers?: string | string[];
};
// !OPUS
