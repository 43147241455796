import { ApolloError, gql } from "@apollo/client/core";
import { useApolloClient } from "@vue/apollo-composable";
import { computedEager } from "@vueuse/core";
import { difference, filter, merge, omit, intersection } from "lodash";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { AbortReason } from "@/core/api/common";
import {
  changeOrderApprovalStatus as _changeOrderApprovalStatus,
  useSelectCartItemsMutation,
  useUnselectCartItemsMutation,
  useRemoveCartItemsMutation,
  useChangeFullCartItemQuantityMutation,
  useAddOrUpdateCartShipmentMutation,
  useAddOrUpdateCartPaymentMutation,
  useChangeFullCartItemsQuantityMutation,
} from "@/core/api/graphql";
import { startEditingOrderApprovalRequest } from "@/core/api/graphql/opus/account";
import { useGetCartByIdQuery } from "@/core/api/graphql/opus/cart";
import { useSyncMutationBatchers } from "@/core/composables";
import { useGoogleAnalytics } from "@/core/composables/useGoogleAnalytics";
import { getMergeStrategyUniqueBy, useMutationBatcher } from "@/core/composables/useMutationBatcher";
import { AddressType, ValidationErrorObjectType } from "@/core/enums";
import { globals } from "@/core/globals";
import { groupByVendor, isEqualAddresses, Logger } from "@/core/utilities";
import { useUser } from "@/shared/account/composables/useUser";
import { useUserAddresses } from "@/shared/account/composables/useUserAddresses";
import { SelectAddressModal } from "@/shared/checkout/components";
import { useModal } from "@/shared/modal";
import { useNotifications } from "@/shared/notification";
import { OpAddOrUpdateAddressModal } from "@/shared/opus";
import { OpusApprovalOrderStatuses } from "../types";
import type {
  OpusCartType,
  OpusCartVendorType,
  OpusShippingMethodType,
  OpusPaymentMethodType,
  OpusPaymentType,
  LineItemType,
  OpusShipmentType,
  OrderApprovalRequestType,
  OpusInputChangeOrderApprovalRequestStatusType,
  InputShipmentType,
  AddOrUpdateCartShipmentMutationVariables,
  AddOrUpdateCartShipmentMutation,
  AddOrUpdateCartPaymentMutationVariables,
  AddOrUpdateCartPaymentMutation,
  InputPaymentType,
  InputAddressType,
  OpusMemberAddressType,
  OpusCartAddressType,
} from "@/core/api/graphql/types";
import type { VendorGroupType } from "@/core/types";
import type { OpVendorCartType, OpusPaymentExtraFieldsType } from "@/shared/opus";

const CartItemsSelectionFragment = gql`
  fragment CartItemsSelectionFragment on OpusCartType {
    items {
      id
      selectedForCheckout
    }
  }
`;

export function useUserOrderForApproval(cartId: string) {
  const switchToEditModeLoading = ref(false);
  const { t } = useI18n();
  const { openModal, closeModal } = useModal();
  const notifications = useNotifications();
  const { user, isCorporateMember } = useUser();
  const ga = useGoogleAnalytics();
  const { client } = useApolloClient();

  const updateApprovalStatusLoading = ref(false);
  const { contactAddresses: personalAddresses, fetchAddresses: fetchPersonalAddresses } = useUserAddresses();
  const billingAddresses = computed<OpusMemberAddressType[]>(() => {
    const { firstName, lastName } = user.value.contact ?? {};
    return personalAddresses.value?.billingAddresses.map((address) => ({ ...address, firstName, lastName })) || [];
  });

  const { result: query, loading: cartLoading, refetch, options } = useGetCartByIdQuery(cartId);

  const cart = computed(() => query.value?.cart as OpusCartType | undefined);

  const shipment = computed(() => cart.value?.shipments[0]);
  const payment = computed(() => cart.value?.payments[0]);
  const shipments = computed<OpusShipmentType[]>(() => cart.value?.shipments ?? []);
  const payments = computed<OpusPaymentType[]>(() => cart.value?.payments ?? []);
  const lineItemsGroupedByVendor = computed(() => groupByVendor(cart.value?.items ?? []));

  const selectedLineItems = computed(() => cart.value?.items?.filter((item) => item.selectedForCheckout) ?? []);

  const availableShippingMethods = computed(() => cart.value?.availableShippingMethods ?? []);
  const availablePaymentMethods = computed(() => cart.value?.availablePaymentMethods ?? []);

  const opusVendorCarts = computed<OpVendorCartType[]>(() => {
    const result: OpVendorCartType[] = [];

    lineItemsGroupedByVendor.value.forEach((group: VendorGroupType<LineItemType>) => {
      if (group.vendor) {
        const vendorId = group.vendor.id;
        const vendorShipment = shipments.value.find((item: OpusShipmentType) => item.vendor?.id === vendorId);
        const vendorPayment = payments.value.find((item: OpusPaymentType) => item.vendor?.id === vendorId);

        const vendorAvailableVendorPaymentMethods = group.vendor.supplier?.paymentMethods!.length
          ? availablePaymentMethods.value.filter(
              (item: OpusPaymentMethodType) => item.name && group.vendor?.supplier?.paymentMethods!.includes(item.name),
            )
          : availablePaymentMethods.value;

        const vendorAvailableVendorShippingMethods = availableShippingMethods.value.filter(
          (item: OpusShippingMethodType) => item.vendorId === vendorId,
        ).length
          ? availableShippingMethods.value.filter((item: OpusShippingMethodType) => item.vendorId === vendorId)
          : availableShippingMethods.value.filter((item: OpusShippingMethodType) => !item.vendorId);

        const vendorCartInfo = cart.value?.vendors?.find(
          (vendorItem: OpusCartVendorType) => vendorId === vendorItem.vendor?.id,
        );

        result.push({
          vendor: group.vendor,
          items: group.items,
          shipment: vendorShipment,
          payment: vendorPayment,
          availableVendorPaymentMethods: vendorAvailableVendorPaymentMethods,
          availableVendorShippingMethods: vendorAvailableVendorShippingMethods,
          subtotal: vendorCartInfo?.subTotal?.amount ?? 0,
          total: vendorCartInfo?.total?.amount ?? 0,
          taxTotal: vendorCartInfo?.taxTotal?.amount ?? 0,
          fee: vendorCartInfo?.feeTotal?.amount ?? 0,
          contractNumbers: vendorCartInfo?.contractNumbers,
        });
      }
    });

    return result;
  });
  const selectedOpusVendorCarts = computed<OpVendorCartType[]>(() => [
    ...filter(opusVendorCarts.value, (item) => item.subtotal !== 0),
  ]);

  const isValidPaymentMethod = computed<boolean>(() => payments.value?.every((item) => !!item.paymentGatewayCode));
  const isValidShipment = computed<boolean>(() => isValidDeliveryAddress.value && isValidShipmentMethod.value);
  const isValidPayment = computed<boolean>(() => isValidBillingAddress.value && isValidPaymentMethod.value);
  const isValidBillingAddress = computed<boolean>(() =>
    selectedOpusVendorCarts.value?.every(
      (item) => !!item.payment?.billingAddress || item.payment?.paymentGatewayCode === "PurchaseOrderPaymentMethod",
    ),
  );
  const filteredShipments = computed<OpusShipmentType[]>(() => {
    return shipments.value?.filter((shipmentItem) =>
      selectedOpusVendorCarts.value.some((vendorCartItem) => shipmentItem.vendor?.id === vendorCartItem.vendor.id),
    );
  });
  const isValidShipmentMethod = computed<boolean>(() =>
    filteredShipments.value?.every((item) => !!item.shipmentMethodCode),
  );
  const isValidDeliveryAddress = computed<boolean>(
    () =>
      filteredShipments.value.length === selectedOpusVendorCarts.value.length &&
      filteredShipments.value?.every((item) => !!item.deliveryAddress),
  );

  const hasValidationErrors = computedEager(() => {
    const restrictedErrors = new Set([
      "DELIVERY_TO_REGION_NOT_AVAILABLE",
      "PRODUCT_QTY_CHANGED",
      "CART_PRODUCT_UNAVAILABLE",
      "PRODUCT_FFC_QTY",
    ]);

    if (cart.value?.validationErrors) {
      const hasRestrictedError = cart.value.validationErrors?.some((error) =>
        restrictedErrors?.has(error?.errorCode ?? ""),
      );

      const hasOtherErrors = cart.value.validationErrors?.some(
        (error) => !restrictedErrors?.has(error?.errorCode ?? ""),
      );

      if (hasRestrictedError && !hasOtherErrors) {
        return false;
      }
    }
    return (
      cart.value?.validationErrors?.some(
        (error) =>
          (getObjectType(error.objectType) === ValidationErrorObjectType.CartProduct &&
            selectedLineItems.value?.some((item) => item.productId === error.objectId)) ||
          (getObjectType(error.objectType) === ValidationErrorObjectType.LineItem &&
            selectedLineItems.value?.some((item) => item.id === error.objectId)),
      ) ?? selectedLineItems.value?.some((item) => item.validationErrors?.length)
    );
  });
  function getObjectType(objectType?: string) {
    return objectType?.includes("Opus") ? objectType.slice(4) : objectType;
  }
  const isValidCheckout = computed<boolean>(
    () => isValidShipment.value && isValidPayment.value && !hasValidationErrors.value && !!selectedItemIds.value.length,
  );

  const { mutate: _selectCartItemsMutation } = useSelectCartItemsMutation(cart);
  const { mutate: _unselectCartItemsMutation } = useUnselectCartItemsMutation(cart);
  const selectCartBatcher = useMutationBatcher(_selectCartItemsMutation);
  const unselectCartBatcher = useMutationBatcher(_unselectCartItemsMutation);
  const { add: _selectCartItems, loading: selectLoading, overflowed: selectOverflowed } = selectCartBatcher;
  const { add: _unselectCartItems, loading: unselectLoading, overflowed: unselectOverflowed } = unselectCartBatcher;
  const selectionOverflowed = computed(() => selectOverflowed.value || unselectOverflowed.value);
  const selectionLoading = computed(() => selectLoading.value || unselectLoading.value);

  useSyncMutationBatchers(selectCartBatcher, unselectCartBatcher, ({ args, anotherBatcher }) => {
    if (!anotherBatcher.loading.value) {
      return;
    }

    const mutationIds = args.command?.lineItemIds ?? [];
    const anotherBatcherIds = anotherBatcher.arguments.value?.command?.lineItemIds ?? [];
    const intersectionIds = intersection(anotherBatcherIds, mutationIds);

    if (intersectionIds.length > 0) {
      anotherBatcher.abort();
      const ids = difference(anotherBatcherIds, intersectionIds);
      if (ids.length > 0) {
        void anotherBatcher.add({ command: { lineItemIds: ids } }, undefined, false);
      }
    }
  });

  const selectedItemIds = computed(() => selectedLineItems.value.map((item) => item.id));

  // Have to update cache explicitly because mutations can be aborted and cache will be rolled back if we use optimisticResponse in mutations. Which cause UI inconsistencies.
  function updateSelectionCache(ids: string[], type: "select" | "unselect") {
    if (!cart.value) {
      return;
    }
    client.cache.updateFragment(
      {
        id: client.cache.identify(cart.value),
        fragment: CartItemsSelectionFragment,
      },
      (data: { items: LineItemType[] | undefined } | null) => {
        return {
          items: data?.items?.map((item: LineItemType) => ({
            ...item,
            selectedForCheckout:
              type === "select"
                ? ids.includes(item.id) || item.selectedForCheckout
                : item.selectedForCheckout && !ids.includes(item.id),
          })),
        };
      },
    );
  }

  function selectCartItems(ids: string[]): void {
    updateSelectionCache(ids, "select");
    void _selectCartItems({
      command: {
        lineItemIds: ids,
      },
    });
  }

  function unselectCartItems(ids: string[]): void {
    updateSelectionCache(ids, "unselect");
    void _unselectCartItems({
      command: {
        lineItemIds: ids,
      },
    });
  }

  const { mutate: _removeItems, loading: removeItemsLoading } = useRemoveCartItemsMutation(cart);
  async function removeItems(lineItemIds: string[]): Promise<void> {
    await _removeItems(
      { command: { lineItemIds } },
      {
        optimisticResponse: {
          removeCartItems: {
            ...cart.value!,
            items: cart.value!.items.filter((item) => !lineItemIds.includes(item.id)),
          },
        },
      },
    );
  }

  const { mutate: _changeItemQuantity, loading: changeItemQuantityLoading } =
    useChangeFullCartItemQuantityMutation(cart);
  async function changeItemQuantity(lineItemId: string, quantity: number): Promise<void> {
    await _changeItemQuantity({ command: { lineItemId, quantity } });
  }

  const { mutate: _changeItemsQuantity, loading: changeItemsQuantityLoading } =
    useChangeFullCartItemsQuantityMutation(cart);
  const { add, overflowed: changeItemQuantityBatchedOverflowed } = useMutationBatcher(_changeItemsQuantity, {
    mergeStrategy: getMergeStrategyUniqueBy("lineItemId"),
  });
  async function changeItemQuantityBatched(lineItemId: string, quantity: number): Promise<void> {
    try {
      await add({ command: { cartItems: [{ lineItemId, quantity }] } });
    } catch (error) {
      if (error instanceof ApolloError && error.networkError?.toString() === (AbortReason.Explicit as string)) {
        return;
      }
      Logger.error(changeItemQuantityBatched.name, error);
    }
  }

  const { mutate: _addOrUpdateShipment, loading: addOrUpdateShipmentLoading } =
    useAddOrUpdateCartShipmentMutation(cart);
  async function updateShipment(value: InputShipmentType): Promise<void> {
    await _addOrUpdateShipment(
      { command: { shipment: value } },
      {
        optimisticResponse: (vars, { IGNORE }) => {
          if ((vars as AddOrUpdateCartShipmentMutationVariables).command.shipment.id === undefined) {
            return IGNORE as AddOrUpdateCartShipmentMutation;
          }
          return {
            addOrUpdateCartShipment: merge({}, cart.value!, {
              shipments: cart.value!.shipments,
            }),
          };
        },
      },
    );
  }

  async function changeOrderApprovalStatus(
    payload: OpusInputChangeOrderApprovalRequestStatusType,
  ): Promise<OrderApprovalRequestType> {
    updateApprovalStatusLoading.value = true;
    try {
      const result = await _changeOrderApprovalStatus(payload);

      notifications.success({
        text:
          (payload.newStatus as OpusApprovalOrderStatuses) === OpusApprovalOrderStatuses.Approved
            ? t("common.messages.order_approved")
            : t("common.messages.order_rejected"),
        duration: 10000,
        single: true,
      });
      return result;
    } catch (e) {
      Logger.error(`${useUserOrderForApproval.name}.${changeOrderApprovalStatus.name}`, e);
      throw e;
    } finally {
      updateApprovalStatusLoading.value = false;
      if (cart.value && (payload.newStatus as OpusApprovalOrderStatuses) === OpusApprovalOrderStatuses.Approved) {
        ga.purchase(cart.value, payload.approvalRequestId, "manually_approved");
      }
    }
  }

  const { mutate: _addOrUpdatePayment, loading: addOrUpdatePaymentLoading } = useAddOrUpdateCartPaymentMutation(cart);
  async function updatePayment(value: InputPaymentType, value2?: OpusPaymentExtraFieldsType): Promise<void> {
    await _addOrUpdatePayment(
      { command: { payment: value, paymentExtension: value2 } },
      {
        optimisticResponse: (vars, { IGNORE }) => {
          if ((vars as AddOrUpdateCartPaymentMutationVariables).command.payment.id === undefined) {
            return IGNORE as AddOrUpdateCartPaymentMutation;
          }
          return {
            addOrUpdateCartPayment: merge({}, cart.value!, {
              payments: cart.value!.payments,
            }),
          };
        },
      },
    );
  }

  async function setShippingMethod(method: OpusShippingMethodType, vendorCart?: OpVendorCartType): Promise<void> {
    await updateShipment({
      id: vendorCart?.shipment?.id,
      price: method.price?.amount,
      shipmentMethodCode: method.code,
      shipmentMethodOption: method.optionName,
      vendorId: vendorCart?.vendor.id,
    });
  }

  async function setPaymentMethod(method: OpusPaymentMethodType, vendorCart?: OpVendorCartType): Promise<void> {
    await updatePayment({
      id: vendorCart?.payment?.id,
      paymentGatewayCode: method.code,
      vendorId: vendorCart?.vendor.id,
    });
  }

  async function updatePaymentDetails(paymentExtraFields: OpusPaymentExtraFieldsType, vendorCart?: OpVendorCartType) {
    await updatePayment(
      {
        id: vendorCart?.payment?.id,
        vendorId: vendorCart?.vendor.id,
        comment: paymentExtraFields.comment,
      },
      {
        purchaseOrderNumber: paymentExtraFields.purchaseOrderNumber,
        generalLedgerNumber: paymentExtraFields.generalLedgerNumber,
        requisitionNumber: paymentExtraFields.requisitionNumber,
      },
    );
  }

  function onBillingAddressChange(vendorCart?: OpVendorCartType): void {
    billingAddresses.value.length
      ? openSelectAddressModal(AddressType.Billing, billingAddresses.value, vendorCart)
      : openAddOrUpdateAddressModal(AddressType.Billing, payment.value?.billingAddress, vendorCart);
  }

  function openSelectAddressModal(
    addressType: AddressType,
    addresses: OpusMemberAddressType[],
    vendorCart?: OpVendorCartType,
  ): void {
    const vendorId = vendorCart?.vendor.id;
    openModal({
      component: SelectAddressModal,

      props: {
        addresses: addresses,
        currentAddress:
          addressType === AddressType.Billing
            ? payments.value?.find((item: OpusPaymentType) => item.vendor?.id === vendorId)?.billingAddress
            : shipments.value?.find((item: OpusShipmentType) => item.vendor?.id === vendorId)?.deliveryAddress,
        isCorporateAddresses: isCorporateMember.value,

        async onResult(address?: OpusMemberAddressType) {
          if (!address) {
            return;
          }

          const inputAddress: InputAddressType = {
            ...omit(address, ["id", "isDefault", "description", "isActive", "isFavorite"]),
            addressType,
          };

          await updateBillingOrDeliveryAddress(addressType, inputAddress, vendorCart);
        },

        onAddNewAddress() {
          setTimeout(() => {
            openAddOrUpdateAddressModal(addressType, undefined, vendorCart);
          }, 500);
        },
      },
    });
  }

  function openAddOrUpdateAddressModal(
    addressType: AddressType,
    editableAddress?: OpusMemberAddressType | OpusCartAddressType,
    vendorCart?: OpVendorCartType,
  ): void {
    openModal({
      component: OpAddOrUpdateAddressModal,
      props: {
        new: true,
        address: {
          firstName: user.value.contact?.firstName,
          lastName: user.value.contact?.lastName,
          email: user.value.email,
          phone: user.value.phoneNumber,
          organization: user.value.contact?.organizations?.items?.[0].name,
          countryCode: "USA",
          countryName: "United States of America",
          description: "",
        },
        enableAddressValidation: addressType === AddressType.Shipping,
        async onResult(address: OpusMemberAddressType) {
          closeModal();

          const inputAddress: InputAddressType = {
            ...omit(address, ["id", "isDefault", "description", "isActive", "isFavorite"]),
            addressType,
          };

          await updateBillingOrDeliveryAddress(addressType, inputAddress, vendorCart);
          await fetchPersonalAddresses();
        },
      },
    });
  }

  async function updateBillingOrDeliveryAddress(
    addressType: AddressType,
    inputAddress: InputAddressType,
    vendorCart?: OpVendorCartType,
  ): Promise<void> {
    if (
      addressType === AddressType.Billing &&
      (!payment.value?.billingAddress || !isEqualAddresses(payment.value?.billingAddress, inputAddress))
    ) {
      await updatePayment({
        id: vendorCart?.payment?.id,
        billingAddress: omit(inputAddress, "isActive"),
        vendorId: vendorCart?.vendor.id,
      });
    } else {
      await updateShipment({
        id: vendorCart?.shipment?.id,
        deliveryAddress: omit(inputAddress, "isActive"),
        vendorId: vendorCart?.vendor.id,
      });
    }
  }

  async function initialize() {
    await fetchPersonalAddresses();
  }

  async function editOrder(approvalRequestId: string) {
    switchToEditModeLoading.value = true;
    try {
      const { id: editedCartId } = await startEditingOrderApprovalRequest(approvalRequestId);
      void switchToCart(editedCartId);
    } catch (e) {
      Logger.error(`${useUserOrderForApproval.name}.${editOrder.name}`, e);
    } finally {
      switchToEditModeLoading.value = false;
    }
  }

  function switchToCart(newCartId: string) {
    const { storeId, cultureName, currencyCode, userId } = globals;

    const variables = {
      storeId,
      cultureName,
      currencyCode,
      userId,
      cartId: newCartId,
    };

    void refetch(variables);
  }

  return {
    loading: computed(() => cartLoading.value || updateApprovalStatusLoading.value || switchToEditModeLoading.value),
    changingProceed: computed(
      () =>
        changeItemQuantityLoading.value ||
        changeItemsQuantityLoading.value ||
        selectionLoading.value ||
        addOrUpdateShipmentLoading.value ||
        addOrUpdatePaymentLoading.value ||
        switchToEditModeLoading.value ||
        removeItemsLoading.value,
    ),
    order: computed(() => cart.value),
    opusVendorCarts,
    selectedOpusVendorCarts,
    shipment,
    payment,
    lineItemsGroupedByVendor,
    selectedLineItems,
    selectedItemIds,
    isValidCheckout,
    removeItems,
    changeItemQuantity,
    changeItemQuantityBatched,
    changeItemQuantityBatchedOverflowed,
    selectCartItems,
    unselectCartItems,
    selectionOverflowed,
    changeOrderApprovalStatus,
    updateShipment,
    updatePayment,
    setShippingMethod,
    setPaymentMethod,
    updatePaymentDetails,
    onBillingAddressChange,
    initialize,
    switchToCart,
    options,
    editOrder,
  };
}
